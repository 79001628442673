import Cookies from 'js-cookie';

document.querySelectorAll<HTMLElement>('[data-cookie]').forEach(($el) => {
  const { cookie: name } = $el.dataset;

  if (name) {
    const value = Cookies.get(name) ?? '';

    if ($el instanceof HTMLInputElement || $el instanceof HTMLTextAreaElement) {
      $el.value = value;
    } else {
      $el.innerText = value;
    }
  }
});
