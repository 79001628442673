const elementIndex = ($element: Element): number => {
  let index = 0;
  let $currentElement: Element | null = $element;

  // eslint-disable-next-line no-cond-assign
  while (($currentElement = $currentElement.previousElementSibling)) {
    index += 1;
  }

  return index;
};

export default elementIndex;
