import A11yDialog from 'a11y-dialog';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { on } from 'delegated-events';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import abort from '../../../javascripts/utils/abort';
import moveFocus from '../../../javascripts/utils/moveFocus';

type OverlayOptions = {
  destroyAfterClose?: boolean;
  redirectAfterClose?: boolean;
};

const dialogs = new Map<HTMLElement, A11yDialog>();

const createOverlay = ($overlay: HTMLElement, options: OverlayOptions | null = null): A11yDialog => {
  if (dialogs.has($overlay)) {
    return dialogs.get($overlay) as A11yDialog;
  }

  const isOpen = $overlay.getAttribute('aria-hidden') !== 'true';
  const dialog = new A11yDialog($overlay);
  const previouslyFocused = document.activeElement;
  const $overlayScroll = $overlay.querySelector('.overlay__overlay') ?? abort();
  const $overlayContent = $overlay.querySelector<HTMLElement>('.overlay__content') ?? abort();

  // Save dialog for later
  dialogs.set($overlay, dialog);

  // Prevent navigation if close button is a link
  $overlay.querySelector('a.overlay__close')?.addEventListener('click', (event) => {
    event.preventDefault();
  });

  $overlay.addEventListener('overlay:scroll-to-top', () => {
    $overlay.querySelector('.overlay__overlay')?.scrollTo({ top: 0 });
  });

  $overlayScroll.addEventListener(
    'scroll',
    () => {
      $overlay.classList.toggle('overlay--scrolled', $overlayScroll.scrollTop > 10);
    },
    { passive: true },
  );

  $overlay.dispatchEvent(new Event('overlay:init', { bubbles: true }));

  dialog.on('show', () => {
    // Send event
    $overlay.dispatchEvent(new Event('overlay:show', { bubbles: true }));

    // Enable close button
    $overlay.querySelector('.overlay__close')?.removeAttribute('disabled');

    // Scroll lock on overlay scroll container
    disableBodyScroll($overlayScroll);

    // Move focus
    moveFocus($overlayContent);
  });

  dialog.on('hide', () => {
    // Remove scroll lock
    enableBodyScroll($overlayScroll);

    // Make focus on source element invisible
    if (previouslyFocused instanceof HTMLElement) {
      invisibleFocus(previouslyFocused);
    }

    // Set close url as new page URL
    const $closeLink = $overlay.querySelector<HTMLAnchorElement>('a.overlay__close');

    if ($closeLink) {
      window.history.replaceState(null, '', $closeLink.href);
    }

    // Send event
    $overlay.dispatchEvent(new Event('overlay:hide', { bubbles: true }));

    // Redirect after close
    if (options?.redirectAfterClose && $closeLink) {
      document.location = $closeLink.href;
    }

    // Destroy dialog!
    if (options?.destroyAfterClose) {
      dialog.destroy();
      dialogs.delete($overlay);
    }
  });

  dialog.on('destroy', () => {
    $overlay.dispatchEvent(new Event('overlay:destroy', { bubbles: true }));
    $overlay.remove();
  });

  if (isOpen) {
    dialog.show();
  }

  return dialog;
};

export const createOverlays = ($container: HTMLElement | Document) => {
  $container.querySelectorAll<HTMLElement>('.overlay').forEach(($overlay) => {
    createOverlay($overlay, {
      destroyAfterClose: $overlay.getAttribute('data-overlay-destroy-after-close') === 'true',
      redirectAfterClose: $overlay.getAttribute('data-overlay-redirect-after-close') === 'true',
    });
  });
};

createOverlays(document);

on('click', '[data-overlay]', (event) => {
  const { currentTarget: $trigger } = event;

  const $overlay = document.getElementById($trigger.dataset.overlay ?? '');

  if ($overlay) {
    event.preventDefault();

    const overlay = createOverlay($overlay, {
      destroyAfterClose: false,
    });

    overlay.show();

    overlay.on('hide', () => {
      invisibleFocus($trigger);
    });
  }
});

export default createOverlay;
