import whenReady from '../../../javascripts/utils/whenReady';
import { type Configuration } from './useCalendar';

whenReady().then(async () => {
  const $$calendar = document.querySelectorAll<HTMLElement>('.calendar__calendar');

  if ($$calendar.length > 0) {
    const { default: useCalendar } = await import(/* webpackChunkName: "useCalendar" */ './useCalendar');

    $$calendar.forEach(($calendar) => {
      try {
        const configuation = JSON.parse($calendar.dataset.calendar ?? '') as Configuration;
        useCalendar($calendar, configuation);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });
  }
});
