import A11yDialog from 'a11y-dialog';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { on } from 'delegated-events';
import { FocusTrap, createFocusTrap } from 'focus-trap';
import abort from '../../../javascripts/utils/abort';
import moveFocus from '../../../javascripts/utils/moveFocus';

const trapStack: FocusTrap[] = [];

const resetMenu = () => {
  trapStack
    .slice(0)
    .reverse()
    .forEach((focusTrap) =>
      focusTrap.deactivate({
        returnFocus: false,
        onPostDeactivate: () => {},
      }),
    );
};

on('click', 'button[data-menu-open-submenu]', (event) => {
  event.preventDefault();

  const { currentTarget: $button } = event;
  const submenuId = $button.getAttribute('aria-controls') ?? abort();
  const $submenu = document.getElementById(submenuId) ?? abort();
  const $items = $submenu.querySelector<HTMLElement>('.menu__submenu-items') ?? abort();
  const { menuLevel } = $button.dataset;

  if (!$submenu.hidden) {
    return;
  }

  if (menuLevel === '1') {
    resetMenu();
  }

  $submenu.hidden = false;
  $submenu.scroll({ top: 0, behavior: 'instant' });
  $button.setAttribute('aria-expanded', 'true');

  const focusTrap = createFocusTrap($submenu, {
    clickOutsideDeactivates: false,
    allowOutsideClick: true,
    trapStack,
    initialFocus: false,
    returnFocusOnDeactivate: false,
    onUnpause() {
      $submenu.scroll({ top: 0, behavior: 'instant' });
    },
    onPostActivate() {
      moveFocus($items);
      disableBodyScroll($submenu);
    },
    onDeactivate() {
      $button.setAttribute('aria-expanded', 'false');

      const animation = $submenu.animate(
        [
          { transform: 'translateX(0)', opacity: '1' },
          { transform: 'translateX(-100%)', opacity: '0' },
        ],
        {
          duration: 150,
        },
      );

      animation.onfinish = () => {
        $submenu.hidden = true;
      };
    },
    onPostDeactivate() {
      enableBodyScroll($submenu);
      moveFocus($button);
    },
  });

  focusTrap.activate();
});

on('click', 'button[data-menu-close-submenu]', (event) => {
  event.preventDefault();
  const focusTrap = trapStack.slice(-1).pop();
  focusTrap?.deactivate();
});

document.querySelectorAll<HTMLElement>('.menu').forEach(($menu) => {
  const isOpen = $menu.getAttribute('aria-hidden') !== 'true';
  const { menuTriggerId: triggerId } = $menu.dataset;
  const dialog = new A11yDialog($menu);

  const $menuInner = $menu.querySelector('.menu__menu-inner') ?? abort();

  dialog.on('show', () => {
    disableBodyScroll($menuInner);
  });

  dialog.on('hide', () => {
    enableBodyScroll($menuInner);
    resetMenu();
  });

  if (triggerId) {
    const $trigger = document.getElementById(triggerId) ?? abort();
    $trigger.setAttribute('aria-controls', $menu.id);
    $trigger.addEventListener('click', (event) => {
      event.preventDefault();

      dialog.show();
      dialog.on('hide', () => moveFocus($trigger), {
        once: true,
      });
    });
  }

  if (isOpen) {
    dialog.show();
  }
});
