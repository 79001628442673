import abort from '../../../javascripts/utils/abort';
import elementIndex from '../../../javascripts/utils/elementIndex';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

class Stage {
  $stage: HTMLElement;
  $track: HTMLElement;
  $slides: HTMLElement;
  $$slide: NodeListOf<HTMLElement>;
  $currentSlide: HTMLElement;
  $nextButton: HTMLButtonElement | null = null;
  $prevButton: HTMLButtonElement | null = null;
  resizeObserver: ResizeObserver;

  constructor($stage: HTMLElement) {
    // Find all elements
    this.$stage = $stage;
    this.$track = $stage.querySelector<HTMLElement>('.stage__track') ?? abort();
    this.$slides = $stage.querySelector<HTMLElement>('.stage__slides') ?? abort();
    this.$$slide = this.$stage.querySelectorAll<HTMLElement>('.stage__slide');
    this.$prevButton = this.$stage.querySelector<HTMLButtonElement>('button[data-stage-move="prev"]');
    this.$nextButton = this.$stage.querySelector<HTMLButtonElement>('button[data-stage-move="next"]');

    // Init resize observer
    this.resizeObserver = new ResizeObserver(this.#handleResize.bind(this));
    this.resizeObserver.observe(this.$stage);

    // Use first slide as current slide
    [this.$currentSlide] = this.$$slide;
    this.resizeObserver.observe(this.$currentSlide);

    // Attach event handlers
    this.$prevButton?.addEventListener('click', this.#handlePrevClick.bind(this));
    this.$nextButton?.addEventListener('click', this.#handleNextClick.bind(this));
    this.$track.addEventListener('keydown', this.#handleKeydown.bind(this));

    // Add attributes to slides
    this.$$slide.forEach(($slide) => {
      const currentSlide = $slide === this.$currentSlide;
      $slide.setAttribute('aria-hidden', currentSlide ? 'false' : 'true');
      $slide.toggleAttribute('inert', !currentSlide);
    });

    // Set height
    this.update(true);

    // Add attributes to track
    this.$track.scrollLeft = 0;
    this.$track.setAttribute('aria-live', 'polite');

    // Finish
    this.$stage.classList.add('stage--initialized');
  }

  #handleResize() {
    this.update(true);
  }

  #handleNextClick(event: MouseEvent) {
    event.preventDefault();
    invisibleFocus(this.$nextButton as HTMLButtonElement);
    this.move(1);
  }

  #handlePrevClick(event: MouseEvent) {
    event.preventDefault();
    invisibleFocus(this.$prevButton as HTMLButtonElement);
    this.move(-1);
  }

  #handleKeydown(event: KeyboardEvent) {
    const { key } = event;

    // eslint-disable-next-line default-case
    switch (key) {
      case 'Home':
        event.preventDefault();
        this.move(0, false);

        break;

      case 'End':
        event.preventDefault();
        this.move(this.$$slide.length - 1, false);

        break;

      case 'ArrowLeft':
        event.preventDefault();
        this.move(-1);

        break;

      case 'ArrowRight':
        event.preventDefault();
        this.move(1);

        break;
    }
  }

  update(instant = false) {
    const { height, width } = this.$currentSlide.getBoundingClientRect();
    const currentSlide = elementIndex(this.$currentSlide);

    if (instant) {
      this.$track.style.transitionDuration = '0s';
      this.$slides.style.transitionDuration = '0s';

      this.$track.addEventListener(
        'transitionend',
        () => {
          this.$track.style.transitionDuration = '';
        },
        { once: true },
      );

      this.$slides.addEventListener(
        'transitionend',
        () => {
          this.$slides.style.transitionDuration = '';
        },
        { once: true },
      );
    } else {
      this.$slides.style.transitionDuration = '';
      this.$track.style.transitionDuration = '';
    }

    requestAnimationFrame(() => {
      this.$track.style.height = `${height}px`;
      this.$slides.style.translate = `-${currentSlide * width}px 0`;
    });
  }

  move(value = 0, relative = true) {
    const currentSlide = elementIndex(this.$currentSlide);
    const nextSlide = relative ? currentSlide + value : value;

    // Out of range
    if (nextSlide < 0 || nextSlide >= this.$$slide.length) {
      return;
    }

    // Hide current slide
    this.$currentSlide.setAttribute('aria-hidden', 'true');
    this.$currentSlide.inert = true;
    this.resizeObserver.unobserve(this.$currentSlide);

    // Make next slide visible
    const $nextSlide = this.$$slide[nextSlide];
    $nextSlide.setAttribute('aria-hidden', 'false');
    $nextSlide.inert = false;

    // Make next slide the new current slide
    this.$currentSlide = $nextSlide;
    this.update();

    // Annonce change
    this.$track.setAttribute('aria-busy', 'true');
    requestAnimationFrame(() => {
      this.$track.setAttribute('aria-busy', 'false');
    });

    // Update buttons
    this.$prevButton?.toggleAttribute('disabled', nextSlide === 0);
    this.$nextButton?.toggleAttribute('disabled', nextSlide === this.$$slide.length - 1);

    // Observe for changes
    this.resizeObserver.unobserve($nextSlide);
  }
}

const stageInstances = new Map<HTMLElement, Stage>();

document.querySelectorAll<HTMLElement>('.stage').forEach(($stage) => {
  stageInstances.set($stage, new Stage($stage));
});

export const getStageInstance = ($stage: HTMLElement): Stage =>
  stageInstances.get($stage) ?? stageInstances.set($stage, new Stage($stage)).get($stage) ?? abort();

export default Stage;
