import { on } from 'delegated-events';
import htmx from 'htmx.org';
import invisibleFocus from '../utils/invisibleFocus';

htmx.config.requestClass = 'is-loading';

on('mousedown', '[data-hx-trigger="click"]', (event) => {
  const { currentTarget } = event;
  invisibleFocus(currentTarget);
});

htmx.on('htmx:beforeRequest', (event) => {
  const { elt } = event.detail;
  invisibleFocus(elt);
});
