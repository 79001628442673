import createTooltip from './createTooltip';

const createAlertTooltip = async (message: string, $reference: HTMLElement, $anchor = document.body, timeout = 2) => {
  // Create alert
  const $alert = document.createElement('div');
  $alert.role = 'alert';
  $alert.innerText = message;

  // Insert and position tooltip
  $anchor.appendChild($alert);
  const cleanup = await createTooltip($alert, $reference);

  // Hide after timeout
  $alert.addEventListener('transitionend', () => {
    cleanup();
    $alert.remove();
  });

  setTimeout(() => {
    $alert.style.opacity = '0';
  }, timeout * 1000);
};

export default createAlertTooltip;
