const filterOptions = (options: string[], filter: string, exclude: string[] = []) =>
  options.filter((option) => {
    const matches = option.toLowerCase().indexOf(filter.toLowerCase()) === 0;
    return matches && exclude.indexOf(option) < 0;
  });

const getIndexByLetter = (options: string[], filter: string, startIndex = 0) => {
  const orderedOptions = [...options.slice(startIndex), ...options.slice(0, startIndex)];

  const firstMatch = filterOptions(orderedOptions, filter)[0];
  const allSameLetter = (array: string[]) => array.every((letter) => letter === array[0]);

  if (firstMatch) {
    return options.indexOf(firstMatch);
  }

  if (allSameLetter(filter.split(''))) {
    const matches = filterOptions(orderedOptions, filter[0]);
    return options.indexOf(matches[0]);
  }

  return -1;
};

export default getIndexByLetter;
