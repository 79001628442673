import { on } from 'delegated-events';

on('click', '[data-set-values]', (event) => {
  const { currentTarget: $trigger } = event;
  const { setValues: setValuesJson = '{}' } = $trigger.dataset;
  const setValues: Record<string, string | string[]> = JSON.parse(setValuesJson);

  const $form = $trigger.closest('form');
  if (!$form) {
    return;
  }

  Object.entries(setValues).forEach(([name, value]) => {
    const $$control = $form.querySelectorAll<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(
      `[name="${name}"], [name="${name}[]"]`,
    );

    $$control.forEach(($control) => {
      if ($control instanceof HTMLInputElement && ($control.type === 'radio' || $control.type === 'checkbox')) {
        if ((Array.isArray(value) && value.includes($control.value)) || $control.value === value) {
          $control.checked = true;
        }
      } else {
        $control.value = value.toString();
      }
    });
  });
});
