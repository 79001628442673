import { on } from 'delegated-events';
import A11yDialog from 'a11y-dialog';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import abort from '../../../javascripts/utils/abort';
import moveFocus from '../../../javascripts/utils/moveFocus';

on('click', '[data-lightbox]', (event) => {
  const { currentTarget: $trigger } = event;
  const { lightbox } = $trigger.dataset;

  event.preventDefault();

  if (!lightbox) {
    return;
  }

  const $media = $trigger.closest<HTMLElement>('.media') ?? abort();
  const $template = document.querySelector<HTMLTemplateElement>(`#${lightbox}`) ?? abort();
  const $fragment = $template.content.cloneNode(true) as DocumentFragment;

  // Create gallery
  const $lightbox = $fragment.querySelector<HTMLElement>('.media__lightbox') ?? abort();
  const $inner = $lightbox.querySelector<HTMLElement>('.media__lightbox-inner') ?? abort();
  const $image = $inner.querySelector<HTMLElement>('.media__lightbox-image') ?? abort();

  // Resize image
  const resizeImage = () => {
    // Set max-width on too large images
    const { width: galleryWidth, height: galleryHeight } = $inner.getBoundingClientRect();
    const $img = $image.querySelector('img');
    const width = $img?.getAttribute('width');
    const height = $img?.getAttribute('height');

    if (width && height) {
      const ratio = parseInt(width, 10) / parseInt(height, 10);
      const fittedRatio = galleryHeight / (galleryWidth / ratio);

      $image.style.maxWidth = `${Math.min(1, fittedRatio) * 100}%`;
    }
  };

  // On resize
  const resizeOberserver = new ResizeObserver(() => {
    resizeImage();
  });

  // Create a11y dialog
  const dialog = new A11yDialog($lightbox);

  // On hide
  dialog.on('hide', () => {
    // Hide lightbox
    enableBodyScroll($lightbox);
    moveFocus($trigger);
    resizeOberserver.disconnect();

    // Remove from DOM
    $lightbox.remove();
    dialog.destroy();
  });

  // On show
  dialog.on('show', () => {
    resizeOberserver.observe($lightbox);
    disableBodyScroll($lightbox);
    resizeImage();
    moveFocus($lightbox);
  });

  // Show gallery
  $media.appendChild($lightbox);
  dialog.show();
});
