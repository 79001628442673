const whenReady = (): Promise<DocumentReadyState> =>
  new Promise((resolve) => {
    if (document.readyState === 'complete') {
      resolve(document.readyState);
    } else {
      // not fired yet, so let's listen for the event
      window.addEventListener('DOMContentLoaded', () => resolve(document.readyState));
    }
  });

export default whenReady;
