import { on } from 'delegated-events';

on('click', '.switch__switch', (event) => {
  const { currentTarget } = event;
  const $input = currentTarget.closest('.switch')?.querySelector<HTMLInputElement>('.switch__input');

  if ($input) {
    $input.checked = !$input.checked;
  }
});
