import { on } from 'delegated-events';

on('click', '.tab-bar__option', (event) => {
  const { currentTarget: $option } = event;
  const $radio = $option.querySelector<HTMLInputElement>('input[type=radio]');

  if ($radio?.checked) {
    return;
  }

  $option.scrollIntoView({ behavior: 'smooth', inline: 'start' });
});
