import { createFocusTrap, FocusTrap } from 'focus-trap';
import { on } from 'delegated-events';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import createTooltip from '../../../javascripts/utils/createTooltip';
import abort from '../../../javascripts/utils/abort';

let focusTrap: FocusTrap | null = null;
let cleanup: () => void;

on('click', 'button.copyright__button', async (event) => {
  const { currentTarget: $button } = event;
  const $copyright = $button.closest<HTMLDivElement>('.copyright') ?? abort();

  event.preventDefault();

  if (focusTrap) {
    focusTrap.deactivate();
  } else {
    const $tooltip = $copyright?.querySelector<HTMLDivElement>('.copyright__tooltip') ?? abort();
    const $tooltips = document.getElementById('tooltips') ?? document.body;

    $tooltip.hidden = false;
    $tooltips.appendChild($tooltip);

    focusTrap = createFocusTrap($copyright, {
      onDeactivate() {
        $tooltip.hidden = true;
        $copyright.appendChild($tooltip);
        cleanup?.();
        $button.classList.remove('copyright__button--active');
        $button.setAttribute('aria-pressed', 'false');
        invisibleFocus($button);
        focusTrap = null;
      },
      async onActivate() {
        cleanup = await createTooltip($tooltip, $button);
        $button.classList.add('copyright__button--active');
        $button.setAttribute('aria-pressed', 'true');
        invisibleFocus($tooltip);
      },
      initialFocus: false,
      clickOutsideDeactivates: (e: MouseEvent | TouchEvent) =>
        e.target instanceof HTMLElement ? !$tooltip.contains(e.target) : false,
      returnFocusOnDeactivate: false,
      preventScroll: false,
    });

    focusTrap.activate();
  }
});
