import {
  ComputePositionReturn,
  Placement,
  ReferenceElement,
  RootBoundary,
  Strategy,
  arrow,
  autoPlacement,
  autoUpdate,
  computePosition,
  offset,
  shift,
} from '@floating-ui/dom';

type FloatingOptions = {
  rootBoundary?: RootBoundary;
  strategy?: Strategy;
  $reference: ReferenceElement;
  $floating: HTMLElement;
  $arrow?: HTMLElement | null;
  placements?: Placement[];
  onCalculate: (result: ComputePositionReturn) => void;
};

export const calculate = async (options: FloatingOptions) => {
  const { floatingPlacements, floatingBoundary, floatingOffset = '15' } = options.$floating.dataset;
  let $boundary: HTMLElement | null = null;

  if (floatingBoundary) {
    $boundary = document.querySelector(floatingBoundary);
  } else if (options.$reference instanceof Element) {
    $boundary = options.$reference.closest('[data-floating-root]');
  }

  const middleware = [
    offset(parseInt(floatingOffset, 10)),
    autoPlacement({
      allowedPlacements: floatingPlacements ? (floatingPlacements.split(',') as Placement[]) : options.placements,
    }),
    shift({
      rootBoundary: options.rootBoundary,
      boundary: $boundary || document.body,
    }),
  ];

  if (options.$arrow) {
    middleware.push(
      arrow({
        element: options.$arrow,
      }),
    );
  }

  return computePosition(options.$reference, options.$floating, {
    middleware,
    strategy: options.strategy,
  });
};

const createFloating = async (options: FloatingOptions) => {
  options.onCalculate(await calculate(options));

  return autoUpdate(options.$reference, options.$floating, async () => {
    options.onCalculate(await calculate(options));
  });
};

export default createFloating;
