import createFloating from './createFloating';

const createTooltip = async ($tooltip: HTMLElement, $reference: HTMLElement) => {
  $tooltip.classList.add('u-tooltip');

  let $arrow = $tooltip.querySelector<HTMLElement>('.u-tooltip-arrow');

  if (!$arrow) {
    $arrow = document.createElement('span');
    $arrow.classList.add('u-tooltip-arrow');
  }

  return createFloating({
    $reference,
    $floating: $tooltip,
    $arrow,
    strategy: 'fixed',
    rootBoundary: 'viewport',
    onCalculate(result) {
      const {
        placement,
        middlewareData: { arrow },
      } = result;

      $tooltip.dataset.placement = placement;
      $tooltip.style.insetInlineStart = `${result.x}px`;
      $tooltip.style.insetBlockStart = `${result.y}px`;

      if (arrow?.x) {
        $tooltip.style.setProperty('--tooltip-triangle-offset-x', `${arrow.x}px`);
      }

      if (arrow?.y) {
        $tooltip.style.setProperty('--tooltip-triangle-offset-y', `${arrow.y}px`);
      }
    },
  });
};

export default createTooltip;
