import { on } from 'delegated-events';

on('click', '.external-video__preview', (event) => {
  event.preventDefault();

  // Get container
  const { currentTarget: $preview } = event;
  const $externalVideo = $preview.closest<HTMLElement>('.external-video');
  const $code = $externalVideo?.querySelector<HTMLTemplateElement>('.external-video__code');

  if (!$code) {
    return;
  }

  // Unpack template with content
  const $embed = $code.content.cloneNode(true) as DocumentFragment;

  // Delete preview
  Array.from($externalVideo?.children ?? []).forEach(($node) => $node.remove());

  // Add class to iframe
  const $iframe = $embed.querySelector<HTMLIFrameElement>('iframe');
  $iframe?.classList.add('external-video__iframe');

  // Replace YouTube domain with youtube-nocookie.com and enable autoplay
  if ($iframe?.src) {
    const iFrameUrl = new URL($iframe.src);
    if (iFrameUrl.hostname.match(/youtube(-nocookie)?\.com$/)) {
      iFrameUrl.hostname = 'www.youtube-nocookie.com';
      iFrameUrl.searchParams.set('autoplay', '1');
      iFrameUrl.searchParams.set('rel', '0');
    }

    $iframe.src = iFrameUrl.toString();
  }

  // Embed video
  $externalVideo?.appendChild($embed);

  // Send event
  $externalVideo?.dispatchEvent(new Event('external-video:visible'));
});
