import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import abort from '../../../javascripts/utils/abort';

class Accordion {
  $accordion: HTMLDetailsElement;
  $summary: HTMLElement;
  $blocks: HTMLDivElement;
  animation: Animation | null = null;
  isClosing = false;
  isExpanding = false;

  constructor($accordion: HTMLDetailsElement) {
    this.$accordion = $accordion;
    this.$summary = this.$accordion.querySelector('.accordion__summary') ?? abort();
    this.$blocks = this.$accordion.querySelector('.accordion__blocks') ?? abort();
    this.$summary.addEventListener('click', this.onClick.bind(this));
  }

  onClick(event: MouseEvent) {
    event.preventDefault();
    invisibleFocus(this.$summary);

    this.$accordion.style.overflow = 'hidden';

    if (this.isClosing || !this.$accordion.open) {
      this.open();
    } else if (this.isExpanding || this.$accordion.open) {
      this.shrink();
    }
  }

  shrink() {
    this.isClosing = true;

    const startHeight = `${this.$accordion.offsetHeight}px`;
    const endHeight = `${this.$summary.offsetHeight}px`;

    if (this.animation) {
      this.animation.cancel();
    }

    this.animation = this.$accordion.animate(
      {
        height: [startHeight, endHeight],
      },
      {
        duration: 400,
        easing: 'ease-out',
      },
    );

    this.animation.onfinish = () => this.onAnimationFinish(false);
    this.animation.oncancel = () => {
      this.isClosing = false;
    };
  }

  open() {
    this.$accordion.style.height = `${this.$accordion.offsetHeight}px`;
    this.$accordion.open = true;

    requestAnimationFrame(() => this.expand());
  }

  expand() {
    this.isExpanding = true;

    const startHeight = `${this.$accordion.offsetHeight}px`;
    const endHeight = `${this.$summary.offsetHeight + this.$blocks.offsetHeight}px`;

    if (this.animation) {
      this.animation.cancel();
    }

    this.animation = this.$accordion.animate(
      {
        height: [startHeight, endHeight],
      },
      {
        duration: 400,
        easing: 'ease-out',
      },
    );

    this.animation.onfinish = () => this.onAnimationFinish(true);
    this.animation.oncancel = () => {
      this.isExpanding = false;
    };
  }

  onAnimationFinish(open: boolean) {
    this.$accordion.open = open;
    this.animation = null;
    this.isClosing = false;
    this.isExpanding = false;
    this.$accordion.style.height = '';
    this.$accordion.style.overflow = '';
  }
}

const accordionInstances = new Map<HTMLElement, Accordion>();

document.querySelectorAll<HTMLDetailsElement>('.accordion').forEach(($accordion) => {
  accordionInstances.set($accordion, new Accordion($accordion));
});

export const getAccordionInstance = ($accordion: HTMLDetailsElement): Accordion =>
  accordionInstances.get($accordion) ??
  accordionInstances.set($accordion, new Accordion($accordion)).get($accordion) ??
  abort();

export default Accordion;
