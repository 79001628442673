import abort from '../../../javascripts/utils/abort';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

const getXPosition = (event: MouseEvent | TouchEvent | PointerEvent) => {
  if ('TouchEvent' in window && event instanceof TouchEvent) {
    const touchEvent = event.touches[0] || event.changedTouches[0];
    return touchEvent.pageX;
  }

  if ('MouseEvent' in window && event instanceof MouseEvent) {
    return event.pageX;
  }

  if ('PointerEvent' in window && event instanceof PointerEvent) {
    return event.pageX;
  }

  return 0;
};

class Slider {
  $slider: HTMLElement;
  $items: HTMLElement;
  $scrollBar: HTMLElement;
  $thumb: HTMLElement;
  $rightButton: HTMLButtonElement | null = null;
  $leftButton: HTMLButtonElement | null = null;

  #resizeObserver: ResizeObserver;
  #moveHandler: (event: MouseEvent | TouchEvent | PointerEvent) => void;
  #pixelMoved = 0;
  #isDragging = false;
  #thumbPositionY = 0;
  #columnGap = 0;
  #columnWidth = 0;

  constructor(slider: HTMLElement) {
    this.$slider = slider;
    this.$items = this.$slider.querySelector('.slider__items') ?? abort();
    this.$scrollBar = this.$slider.querySelector('.slider__scroll-bar') ?? abort();
    this.$thumb = this.$scrollBar.querySelector('.slider__scroll-bar-thumb') ?? abort();
    this.$leftButton = this.$slider.querySelector<HTMLButtonElement>('button[data-move="left"]');
    this.$rightButton = this.$slider.querySelector<HTMLButtonElement>('button[data-move="right"]');

    // Init resize observer
    this.#resizeObserver = new ResizeObserver(this.#handleResize.bind(this));
    this.#resizeObserver.observe(this.$slider);

    // Add event listeners
    this.#moveHandler = this.#handleMove.bind(this);
    this.$items.addEventListener('scroll', this.#handleScroll.bind(this), { passive: true });
    this.$items.addEventListener('scrollend', this.#handleScrollEnd.bind(this));
    this.$thumb.addEventListener('touchstart', this.#handleMoveStart.bind(this));
    this.$thumb.addEventListener('mousedown', this.#handleMoveStart.bind(this));
    this.$leftButton?.addEventListener('click', this.#handleLeftClick.bind(this));
    this.$rightButton?.addEventListener('click', this.#handleRightClick.bind(this));
    this.$scrollBar.addEventListener('click', this.#handleScrollbarClick.bind(this));

    // Update and calculate on load
    this.update();
    this.updateButtons();
    this.calculate();
  }

  update() {
    requestAnimationFrame(() => {
      this.#thumbPositionY = (this.$items.scrollLeft / this.$items.scrollWidth) * this.$scrollBar.scrollWidth;
      this.$thumb.style.transform = `translate3d(${this.#thumbPositionY}px, 0, 0)`;
    });
  }

  updateButtons() {
    this.$leftButton?.toggleAttribute('disabled', this.$items.scrollLeft === 0);
    this.$rightButton?.toggleAttribute(
      'disabled',
      this.$items.scrollWidth <= this.$items.scrollLeft + this.$items.offsetWidth,
    );
  }

  calculate() {
    // Get column width
    this.#columnGap = parseFloat(window.getComputedStyle(this.$items).columnGap);
    this.#columnWidth = this.$items.firstElementChild?.getBoundingClientRect().width || 0;

    // Get column gap
    const width = (this.$items.offsetWidth / this.$items.scrollWidth) * 100;
    this.$thumb.style.width = `${width}%`;
  }

  left() {
    this.$items.scrollBy({ left: (this.#columnGap + this.#columnWidth) * -1 });
  }

  right() {
    this.$items.scrollBy({ left: this.#columnGap + this.#columnWidth });
  }

  #handleMoveStart(event: MouseEvent | TouchEvent | PointerEvent) {
    event.preventDefault();

    this.#pixelMoved = getXPosition(event);
    this.$items.dataset.state = 'dragging';
    this.#isDragging = true;

    document.body.style.pointerEvents = 'none';

    if (window.MouseEvent && event instanceof MouseEvent) {
      window.addEventListener('mousemove', this.#moveHandler);
      window.addEventListener('mouseup', this.#handleMoveEnd.bind(this), { once: true });
    } else if (window.TouchEvent && event instanceof TouchEvent) {
      window.addEventListener('touchmove', this.#moveHandler);
      window.addEventListener('touchend', this.#handleMoveEnd.bind(this), { once: true });
    } else {
      window.addEventListener('pointermove', this.#moveHandler);
      window.addEventListener('pointerup', this.#handleMoveEnd.bind(this), { once: true });
    }
  }

  #handleMove(event: MouseEvent | TouchEvent | PointerEvent) {
    event.preventDefault();

    const pageX = getXPosition(event);
    const maxPosition = this.$scrollBar.scrollWidth - this.$thumb.scrollWidth;
    const newPosition = Math.min(Math.max(this.#thumbPositionY + pageX - this.#pixelMoved, 0), maxPosition);

    this.$items.scrollLeft = this.$items.scrollWidth * (newPosition / this.$scrollBar.scrollWidth);
    this.$thumb.style.transform = `translate3d(${newPosition}px, 0, 0)`;
  }

  #handleMoveEnd(event: MouseEvent | TouchEvent | PointerEvent) {
    this.#isDragging = false;
    this.#thumbPositionY += getXPosition(event) - this.#pixelMoved;

    window.removeEventListener('mousemove', this.#moveHandler);
    window.removeEventListener('touchmove', this.#moveHandler);
    window.removeEventListener('pointermove', this.#moveHandler);

    document.body.style.pointerEvents = '';

    requestAnimationFrame(() => {
      this.$items.dataset.state = '';
      this.update();
    });
  }

  #handleScroll() {
    if (!this.#isDragging) {
      this.update();
    }
  }

  #handleScrollEnd() {
    this.updateButtons();
  }

  #handleResize() {
    this.update();
    this.updateButtons();
    this.calculate();
  }

  #handleRightClick(event: MouseEvent) {
    event.preventDefault();
    invisibleFocus(event.currentTarget as HTMLButtonElement);
    this.right();
  }

  #handleLeftClick(event: MouseEvent) {
    event.preventDefault();
    invisibleFocus(event.currentTarget as HTMLButtonElement);
    this.left();
  }

  #handleScrollbarClick(event: MouseEvent) {
    event.preventDefault();

    if (event.target === this.$scrollBar) {
      const { left } = this.$scrollBar.getBoundingClientRect();

      if (event.pageX - left < this.#thumbPositionY) {
        this.left();
      } else {
        this.right();
      }
    }
  }
}

const sliderInstances = new Map<HTMLElement, Slider>();

document.querySelectorAll<HTMLElement>('.slider').forEach(($slider) => {
  sliderInstances.set($slider, new Slider($slider));
});

export const getStageInstance = ($slider: HTMLElement): Slider =>
  sliderInstances.get($slider) ?? sliderInstances.set($slider, new Slider($slider)).get($slider) ?? abort();

export default Slider;
