import { on } from 'delegated-events';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

on('click', '.archive__additional-toggle', (event) => {
  const { currentTarget: $additionalToggle } = event;

  invisibleFocus($additionalToggle);
  $additionalToggle.dataset.dataArchiveUserClicked = '1';
});

document.querySelectorAll<HTMLDetailsElement>('.archive__additional').forEach(($additional) => {
  const breakpoint = window.getComputedStyle($additional).getPropertyValue('--_archive-sidebar-breakpoint');
  const mediaQuery = window.matchMedia(breakpoint);

  $additional.open = !mediaQuery.matches;
  mediaQuery.addEventListener('change', (event) => {
    const $toggle = $additional.querySelector<HTMLElement>('.archive__additional-toggle');

    if ($toggle?.dataset.dataArchiveUserClicked === '1') {
      return;
    }

    $additional.open = !event.matches;
  });
});
