import { on } from 'delegated-events';

on('click', 'input.input__input[data-select-on-click]', (event) => {
  const { currentTarget: $input } = event;

  $input.select();
  $input.setSelectionRange(0, $input.value.length);
});

document
  .querySelectorAll<
    HTMLInputElement | HTMLTextAreaElement
  >('input.input__input[data-limit], textarea.input__input[data-limit]')
  .forEach(async ($input) => {
    const { default: useInputLimits } = await import(/* webpackChunkName: "use-input-limits" */ './useInputLimits');
    useInputLimits($input);
  });

document.querySelectorAll<HTMLInputElement>('input.input__input[data-tel]').forEach(async ($input) => {
  const { default: useIntlTelInput } = await import(/* webpackChunkName: "use-intl-tel-input" */ './useIntlTelInput');
  useIntlTelInput($input);
});

document.querySelectorAll<HTMLInputElement>('input.input__input[data-richtext]').forEach(async ($input) => {
  const { default: usePell } = await import(/* webpackChunkName: "use-pell" */ './usePell');
  usePell($input);
});

document.querySelectorAll<HTMLInputElement>('input.input__input[data-tags]').forEach(async ($input) => {
  const { default: useTags } = await import(/* webpackChunkName: "use-tags" */ './useTags');
  useTags($input);
});
