import { on } from 'delegated-events';
import abort from '../../../javascripts/utils/abort';

on('click', 'button[data-page-readspeaker]', (event) => {
  const { currentTarget: $button } = event;

  const $page = $button.closest('.page') ?? abort();
  const $readSpeaker = $page.querySelector<HTMLElement>('.page__readspeaker') ?? abort();

  const $script = document.createElement('script');
  $script.src = '//cdn1.readspeaker.com/script/11021/webReader/webReader.js?pids=wr';
  $script.onload = () => {
    // Make content visible
    $readSpeaker.hidden = false;

    // Start player
    const $playButton = $readSpeaker.querySelector<HTMLAnchorElement>('.rsbtn_play');
    $playButton?.focus();
    $playButton?.click();
  };

  // Load JavaScript
  document.head.appendChild($script);

  // Disable button
  $button.disabled = true;
});
