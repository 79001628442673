const onLostFocus = ($element: Element, callback: ($el: Element) => void): (() => void) => {
  const handler = (event: Event) => {
    if (event.target instanceof Element && !$element.contains(event.target)) {
      document.removeEventListener('click', handler);
      document.removeEventListener('focusout', handler);
      callback($element);
    }
  };

  document.addEventListener('click', handler);
  document.addEventListener('focusout', handler);

  return () => {
    document.removeEventListener('click', handler);
    document.removeEventListener('focusout', handler);
  };
};

export default onLostFocus;
