import { FocusableElement, isFocusable } from 'tabbable';

const invisibleFocus = (
  $element: FocusableElement,
  options: FocusOptions = { preventScroll: true },
  force = false,
): void => {
  let notFocusable = false;

  if (force && !isFocusable($element)) {
    notFocusable = true;
    $element.setAttribute('tabindex', '0');
  }

  const onBlur = () => {
    $element.removeEventListener('blur', onBlur);
    $element.classList.remove('has-invisible-focus');

    if (notFocusable) {
      $element.removeAttribute('tabindex');
    }
  };

  $element.addEventListener('blur', onBlur);
  $element.classList.add('has-invisible-focus');

  $element.focus(options);
};

export default invisibleFocus;
