import { on } from 'delegated-events';
import abort from '../../../javascripts/utils/abort';
import moveFocus from '../../../javascripts/utils/moveFocus';

const rowCounter = new WeakMap<HTMLElement, number>();

const getNextCount = ($element: HTMLElement): number => {
  if (!rowCounter.has($element)) {
    rowCounter.set($element, 0);
  }

  const newCount = (rowCounter.get($element) as number) + 1;
  rowCounter.set($element, newCount);

  return newCount;
};

const updateAddGroupButton = ($button: HTMLButtonElement) => {
  const { fieldAddGroupMax: max } = $button.dataset;
  const $field = $button.closest<HTMLElement>('.field') ?? abort();
  const $fieldGroups = $field.querySelector('.field__groups') ?? abort();

  if (max) {
    $button.disabled = $fieldGroups.querySelectorAll('.field__group').length >= parseInt(max, 10);
  }
};

on('change', 'input[data-field-select-all]', (event) => {
  const { currentTarget: $selectAll } = event;

  $selectAll
    .closest('.field')
    ?.querySelectorAll<HTMLInputElement>('[type="checkbox"]')
    .forEach(($checkbox) => {
      if ($checkbox !== $selectAll) {
        $checkbox.checked = $selectAll.checked;
      }
    });
});

on('click', 'button[data-field-add-group]', (event) => {
  const { currentTarget: $trigger } = event;
  const { fieldAddGroup: templateId = '' } = $trigger.dataset;
  const $template = document.getElementById(templateId) ?? abort();
  const $field = $trigger.closest<HTMLElement>('.field') ?? abort();
  const $fieldGroups = $field.querySelector('.field__groups') ?? abort();

  const $tempEl = document.createElement('div');
  $tempEl.innerHTML = $template.innerHTML.replace(/__ROW__/g, `new${getNextCount($field)}`);

  const $newGroup = $tempEl.querySelector<HTMLElement>(':first-of-type') ?? abort();

  $fieldGroups.appendChild($newGroup);
  moveFocus($newGroup);
  updateAddGroupButton($trigger);
});

on('click', 'button[data-field-delete-group]', (event) => {
  const { currentTarget: $trigger } = event;
  const { fieldDeleteGroup: target = '' } = $trigger.dataset;
  const $target = document.getElementById(target) ?? abort();

  const $addGroupButton = $trigger
    .closest<HTMLElement>('.field')
    ?.querySelector<HTMLButtonElement>('[data-field-add-group]');

  $target.remove();

  if ($addGroupButton) {
    moveFocus($addGroupButton);
    updateAddGroupButton($addGroupButton);
  }
});
