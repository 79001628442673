import whenReady from '../../../javascripts/utils/whenReady';

whenReady().then(() => {
  const setLoaded = ($image: HTMLElement) => {
    const $container = $image.closest<HTMLPictureElement>('.image');

    if ($container) {
      $image.addEventListener(
        'transitionend',
        () => {
          $container.style.backgroundImage = '';
        },
        { once: true },
      );

      $container.classList.add('image--loaded');
    }
  };

  const $$image = document.querySelectorAll<HTMLImageElement>('.image__img');
  $$image.forEach(($image) => {
    if ($image.complete && $image.naturalHeight !== 0) {
      setLoaded($image);
    }
  });

  document.addEventListener(
    'load',
    (event) => {
      const { target: $image } = event;

      if ($image instanceof HTMLElement && $image.matches('.image__img')) {
        setLoaded($image);
      }
    },
    {
      capture: true,
    },
  );
});
