import { on } from 'delegated-events';

on('click', '.embed__button', (event) => {
  event.preventDefault();

  // Get container
  const { currentTarget: $button } = event;
  const $embed = $button.closest<HTMLElement>('.embed');
  const $code = $embed?.querySelector<HTMLTemplateElement>('.embed__code');
  const $wall = $embed?.querySelector<HTMLElement>('.embed__wall');

  if (!$code || !$embed) {
    return;
  }

  // Append code
  $wall?.remove();
  const $content = document.createElement('div');
  $content.className = 'embed__content';
  $embed.appendChild($content);
  $content.appendChild($code.content.cloneNode(true));
});
