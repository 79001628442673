import { on } from 'delegated-events';

on('overlay:hide', '.search-overlay', (event) => {
  const { currentTarget: $target } = event;

  const $searchContent = $target.querySelector('.search-overlay__content');
  if ($searchContent) {
    $searchContent.textContent = '';
  }

  const $searchInput = $target.querySelector<HTMLInputElement>('.search-overlay__form input');
  if ($searchInput) {
    $searchInput.value = '';
  }
});
