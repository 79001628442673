class SocialMediaPosts {
  $container: HTMLElement;

  #$$item: HTMLElement[];
  #$$column: HTMLElement[] = [];
  #resizeObserver: ResizeObserver;

  constructor($container: HTMLElement) {
    this.$container = $container;
    this.#$$item = Array.from(this.$container.querySelectorAll('.social-media-posts__post'));

    // Init resize observer
    this.#resizeObserver = new ResizeObserver(this.#handleResize.bind(this));
    this.#resizeObserver.observe(this.$container);

    // Render
    this.render();
  }

  get columns() {
    const { columnCount } = window.getComputedStyle(this.$container);

    return Math.max(1, parseInt(columnCount, 10));
  }

  #handleResize() {
    this.render();
  }

  render() {
    const { columns } = this;
    const $$column = [...Array(columns)].map(() => {
      const $column = document.createElement('div');
      $column.className = 'social-media-posts__column';
      return $column;
    });

    this.#$$item.forEach(($item, index) => $$column[index % columns].appendChild($item));

    this.#$$column.forEach(($column) => $column.remove());
    $$column.forEach(($column) => this.$container.appendChild($column));
    this.#$$column = $$column;
  }
}

const instances = new Map<HTMLElement, SocialMediaPosts>();

document.querySelectorAll<HTMLElement>('.social-media-posts__posts').forEach(($socialMediaPosts) => {
  instances.set($socialMediaPosts, new SocialMediaPosts($socialMediaPosts));
});

export default SocialMediaPosts;
